import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp'
import testimonialBg from 'assets/images/testimonials-banner-img.webp'

export const homeStyle = {
  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      maxWidth: 'unset',
      minHeight: 'unset !important',

      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px) and (max-width: 1200px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px) and (max-width: 1200px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '.willowcreekway-container': {
    '& .normal-text': {
      fontWeight: 300,
    },

    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        backgroundColor: palette.primary.main,
        '& .hero-header-title, .hero-header-subtitle': {
          color: palette.text.highlight,
        },
      },

    '& .checkout-outer-container, .plan-selection-container': {
      '& .MuiSwitch-thumb': {
        background: palette.background.main,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        border: `1px solid ${palette.secondary.main}`,
        backgroundColor: palette.secondary.main,
      },
      '& .MuiSwitch-track': {
        border: `1px solid ${palette.secondary.main}`,
        background: palette.secondary.main,
      },
    },

    '& .stepperContainer': {
      '& .stepperIconRoot.MuiStepIcon-active, .MuiStepIcon-completed': {
        color: palette.background.primary,
        borderColor: palette.background.primary,
      },
      '& .MuiStepIcon-text': {
        color: palette.text.highlight,
      },
    },

    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundColor: palette.text.highlight,

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '550px',
      },
      '& .section-header': {
        '@media screen and (min-width: 768px)': {
          marginTop: '60px',
        },
      },
      '& .home-hero-title': {
        maxWidth: 600,
        color: palette.text.primary,
      },
      '& .section-header__subtitle-wrapper': {
        margin: '24px 0px',
      },
      '& .home-hero-subtitle': {
        maxWidth: 510,
      },
    },

    /* Benefits - Styles */
    '& .definition-variant-2': {
      '& .benefits-wrapper': {
        '& section-header__title': {
          weight: '600',
        },
      },
      '& #definition-section': {
        '& .benefits-wrapper': {
          padding: '0 24px',
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      '& .MuiSwitch-thumb': {
        background: palette.background.main,
      },
      '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: palette.secondary.main,
      },
      '& .MuiSwitch-track': {
        border: 'none',
        background: palette.secondary.main,
      },
      '& .subscription-bg': {
        height: '100%',
        background: palette.background.primary,
      },
      '& #pricing-benefit-section': {
        '& .planCard': {
          border: 'none',
          '& .price-value, .bold': {
            color: palette.text.primary,
          },
          '&:hover': {
            border: `1px solid ${palette.background.primary}`,
            backgroundColor: palette.background.main,
            color: palette.primary.main,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .MuiButton-containedSecondary': {
            '&:hover': {
              opacity: 1,
            },
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
        '& .subscription-section': {
          objectFit: 'cover',
          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
          '& .plans-loader': {
            color: palette.text.highlight,
          },
        },
        '& .section-header__title, .section-header__subtitle, .subscription-tabs-label, .summary-text, .summary-subtext':
          {
            color: palette.text.highlight,
          },
        '& .savings-summary-link': {
          color: palette.text.highlight,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* How it works - Styles */
    '& .howitworks-section': {
      background: palette.background.main,

      '& .howitworks-wrapper': {
        padding: 20,
      },

      '& .howitworks-wrapper-left': {
        padding: 20,
      },

      '& .makeStyles-root-54': {
        padding: 0,
      },

      '& .working-steps-container:not(:last-child)': {
        paddingBottom: 30,
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      backgroundImage: `url(${testimonialBg})`,
      background: palette.background.secondary,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',

      '@media(min-width: 900px)': {
        padding: 72,
      },

      '& .testimonial-name, & .testimonial-location, & .testimonial-message': {
        color: palette.text.primary,
      },
      
      '& .react-multi-carousel-dot.react-multi-carousel-dot--active': {
        '& button': {
          background: palette.text.alternate,
        },
      },

      '& .testimonialSection': {
        padding: '40px 32px',

        '@media(min-width: 900px)': {
          padding: '40px 16px',
        },
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .section-header': {
        '& .section-header__title': {
          color: palette.text.primary,
        },
        '& .section-header__subtitle': {
          color: palette.text.primary,
        },
      },
      '& .service-plan-table': {
        '& .savings-topheader': {
          color: `${palette.text.highlight} !important`,
        },

        '& .savings-header': {
          color: palette.text.primary,
        },

        '& .savings-subheader': {
          backgroundColor: palette.background.main,
          color: palette.text.primary,
          fontWeight: 600,
        },
      },

      '& .savings-contact-card': {
        backgroundColor: palette.background.footer,
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      '& .hero-static-bg': {
        backgroundColor: palette.background.footer,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .provider-details': {
        border: `1px solid ${palette.text.border}`,
      },
      '& .MuiSwitch-thumb': {
        background: palette.background.main,
      },
      '& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track': {
        backgroundColor: palette.secondary.main,
        opacity: 1
      },
      '& .MuiSwitch-track': {
        background: palette.secondary.main,
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .plans-wrapper': {
        '& .planCard': {
          border: `1px solid ${palette.text.primary}`,
          '&:hover': {
            color: palette.text.primary,
            background: palette.background.main,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.highlight,
          },
          '& .planCardDescription': {
            height: 'calc(100% - 24px)',
          },
        },
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
    },
    '& .faq-contact-section': {
      '& .contact-img-wrapper': {
        width: '64px',
        maxWidth: '64px',
      },
      '& .contact-card-title, .contact-card-subtitle, .provider-contact-details, .provider-contact-link':
        {
          color: palette.text.highlight,
        },
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
    },

    /* Contact Card - FAQ, Success Page */
    '& .faq-contact-wrapper': {
      background: palette.background.alternate,
    },
    '& .mail-link': {
      color: palette.text.primary,
    },

    /* Footer */
    '& .footer-wrapper': {
      background: palette.background.footer,
    },

    /* Dashboard Page - Appointment Header */
    '& .MuiTableRow-root .MuiTableCell-head': {
      color: palette.text.highlight,
    },
  },
};