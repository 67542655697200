export const palette = {
  alternate: {
    main: '#F9F6FF',
    light: '#F9F8F8',
    dark: '#E5EAF4',
  },
  cardShadow: '#f4e9f3',
  type: 'light',
  primary: {
    main: '#1B1464',
    light: '#e8e7ef'
  },
  secondary: {
    main: '#0081C4',
    contrastText: '#0A5285',
  },
  text: {
    primary: '#111111',
    secondary: '#64748B',
    alternate: '#23B0E7',
    disabled: '#CCC',
    highlight: '#FFF',
    success: '#1CA179',
    border: '#E5EAF4',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    main: '#FFFFFF',
    paper: '#FFF',
    default: '#FFF',
    header: '#C3DFFC',
    footer: '#F8FBFE',
    level2: '#f7f8fb',
    level1: '#FFF',
    tableHead: '#0489BE',
    tableSubhead: '#EEF8FB',
    tableBorder: '#7D808880',
    tableAlternate: '#FFFFFF',
    alternate: '#04B1D8',
    success: '#DBEFDC',
    error: '#FFEAEA',
    primary: '#92278E',
    secondary: 'rgba(233, 242, 240, 0.60)',
    light: '#D9DBE9',
    secondaryLight: '#d7dfee'
  },
  verticalBar: {
    used: '#7BD6D0',
    unused: '#7BD6D01a',
    unlimited: '#C0C0C0',
  }
};
